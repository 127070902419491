import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  selectedCity: 'All', // Default to 'All'
  cityList:[],
  markerList :[]

};

// Create slice (reducer and actions)
const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setCity: (state, action) => {
      state.selectedCity = action.payload;
    },
    setCityList: (state, action) =>  {
      state.cityList =  action.payload.map(item => item.city)
    },
	setMarkerLists:(state,action) => {
		state.markerList = action.payload;
	}
  },
});

// Export actions to dispatch
export const { setCity,setMarkerLists,setCityList } = filterSlice.actions;

export default filterSlice.reducer;